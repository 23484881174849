<template>
  <div>
    <div class="i-layout-page-header header-title">
      <span class="ivu-page-header-title l-m-r20">{{ $route.meta.title }}</span>
      <div>
        <!--<div style="float: right" v-if="cardShow == 1 || cardShow == 2">-->
        <!--  <Button class="bnt" type="primary" @click="submit" :loading="loadingExist">保存</Button>-->
        <!--  <Button class="bnt ml20" @click="reast">重置</Button>-->
        <!--</div>-->
      </div>
    </div>

    <Row class="ivu-mt box-wrapper">
      <Col span="3" class="left-wrapper">
        <Menu theme="light" :active-name="1" width="auto">
          <MenuGroup>
            <MenuItem :name="item.id" v-for="(item, index) in menuList" :key="index" @click.native="bindMenuItem(index)">
              {{ item.name }}
            </MenuItem>
          </MenuGroup>
        </Menu>
      </Col>
      <Col span="21" class="right-wrapper">
        <Card :bordered="false" dis-hover v-if="cardShow == 0">
          <Row v-if="cardShow === 0">
            <Col style="width: 310px; height: 550px; margin-right: 30px; position: relative" v-if="isDiy">
              <iframe class="iframe-box" :src="imgUrl" frameborder="0" ref="iframe"></iframe>
              <div class="mask"></div>
            </Col>
            <Col :span="isDiy ? '' : 24" v-bind="isDiy ? grid : ''" :class="isDiy ? 'table' : ''">
              <div class="acea-row row-between-wrapper">
                <Row type="flex">
                  <Col v-bind="grid">
                    <div class="button acea-row row-middle">
                      <Button type="primary" icon="md-add" @click="handleCreate">添加专题页</Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  </div>
</template>

<script>
import mixins from '@/mixins'

export default {
  name: 'list',
  mixins: [mixins],
  data () {
    return {
      cardShow: 0,

      // 左侧数据
      menuList: [
        {
          name: '商城首页',
          id: 1
        },
        {
          name: '商品分类',
          id: 2
        },
        {
          name: '个人中心',
          id: 3
        }
      ],

      // 右侧数据
      isDiy: 1,
      imgUrl: ''
    }
  },
  methods: {
    bindMenuItem (index) {
      this.cardShow = index
    },
    // 处理添加专题
    handleCreate () {}
  }
}
</script>

<style scoped lang="scss">
/deep/ .ivu-menu-vertical .ivu-menu-item-group-title {
  display: none;
}

.left-wrapper {
  background: #fff;
  border-right: 1px solid #f2f2f2;
}

.iframe-box {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 1px solid #eee;
}

.mask {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
}

</style>
